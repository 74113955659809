import React from 'react'
import PropTypes from 'prop-types'
import compose from 'recompose/compose'
import toClass from 'recompose/toClass'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Avatar from '@material-ui/core/Avatar'
import PersonIcon from '@material-ui/icons/Person'
import Typo from '~/components/Typo'
import clsx from 'clsx'
import withIntersectionObserver from '~/utils/withIntersectionObserver'
import Link from '~/components/Link'

const styles = (theme) => ({
  root: {
    display: 'flex',
    textDecoration: 'none',
  },
  info: {
    display: 'flex',
    justifyContent: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    flexDirection: 'column',
    overflow: 'hidden',
  },
  avatar: {
    width: 100,
    height: 100,
    transition: 'all .5s',
    '$root:hover &': {
      borderRadius: 0,
    },
    '$small &': {
      width: 50,
      height: 50,
    },
  },
  small: {},
  name: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  badges: {
    top: 10,
    right: 14,
  },
})

const UserItem = ({
  slug,
  avatar,
  name,
  bio,
  followersCount,
  classes,
  className,
  isVisible,
  small,
}) => {
  return (
    <Link href={`/profil?slug=${slug}`} as={`/profil/${slug}`}>
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a className={clsx(classes.root, { [classes.small]: small }, className)}>
        <Avatar
          className={classes.avatar}
          alt={`${name} avatar`}
          {...(avatar && isVisible
            ? { src: avatar }
            : { children: <PersonIcon /> })}
        />
        <div className={classes.info}>
          <Typo variant="itemPostTitle" className={classes.name}>
            {name}
          </Typo>
          {followersCount > 20 && (
            <Typography color="textSecondary">
              {followersCount} abonné{followersCount > 1 && 's'}
            </Typography>
          )}
          {bio && <Typography>{bio}</Typography>}
        </div>
      </a>
    </Link>
  )
}

export default compose(
  withStyles(styles),
  withIntersectionObserver(0),
  toClass
)(UserItem)

UserItem.propTypes = {
  avatar: PropTypes.string.isRequired,
  bio: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  followersCount: PropTypes.number.isRequired,
}
