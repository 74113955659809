import React from 'react'
import PlayableItem from './PlayableItem'
import EntityItem from './EntityItem'
import UserItem from './UserItem'
import PropTypes from 'prop-types'
import { fromGlobalId } from 'graphql-relay'

interface Props {
  globalId: string
  [x: string]: any
}

const Item = ({ globalId, ...props }: Props) => {
  switch (fromGlobalId(globalId).type) {
    case 'Post':
      return <PlayableItem globalId={globalId} {...props} />
    case 'User':
      return <UserItem globalId={globalId} {...props} />
    default:
      return <EntityItem globalId={globalId} {...props} />
  }
}
Item.defaultProps = {
  slidesToShow: 3,
}
Item.propTypes = {
  globalId: PropTypes.string.isRequired,
}
export default Item
